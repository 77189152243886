/* @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,600,700,900'); */

.remove-btn {
    margin-right: 20px;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 1000ms ease-in;
}


body {
    background-color: #e7f0e9;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.top_bg {
    background-color: #001c41;
    padding: 10px;
}

.top_bg img {
    height: 50px;
}

.login_error {
    color: #e90015;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
    font-weight: 500;
}

.login_box {
    background-color: #fff;
    border-radius: 5px;
    margin: 70px auto 50px auto;
    -moz-box-shadow: 0 3px 7px -3px #222;
    -webkit-box-shadow: 0 3px 7px -3px #222;
    box-shadow: 0 5px 7px -3px #222;
    text-align: left;
    max-width: 400px;
}

.login_box .top_box {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 7px solid #e90015;
    background-color: rgb(85, 171, 218, 0.2);
    padding: 20px 10px;
    text-align: center;
    border-bottom: 1px solid #ccc;
}

.login_box .top_box h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.login_box .form_box {
    padding: 0 30px 50px 30px;
    padding-bottom: 50px;
}

.login_box label {
    margin: 0 0 0px;
    text-align: left;
    font-weight: bold;
    font-size: 13px;
}

.login_box .form-control {
    text-align: left;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
    border-color: #ccc;
}

.login_box .form-control:focus {
    border: 2px solid #0b316b;
    box-shadow: #0b316b;

}

.login_box .btn {
    background-color: #0b316b;
    color: #fff;
    border-radius: 6px;
    font-weight: bold;
    font-size: 12px;
    padding: 10px;
    box-shadow: none;
}

.login_box .link {
    text-decoration: none;
    color: #0b316b;
    font-weight: bold;
    font-size: 14px;
}

.login_bg {
    background-image: url('./bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    height: 100vh;
}

.login_bglayer {
    background-color: rgb(0, 0, 0, 0.5);
    height: 100vh;
}

@media(max-width: 768px) {
    .login_box {
        margin: auto;
        margin-top: 50px;
        max-width: 400px;
    }
}