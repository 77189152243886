body {font-family:'Segoe UI', Arial, Helvetica, sans-serif; font-weight:400;background:#eff0f3;color:#555;}
.myresponse {padding: 6px; border-radius: 3px; margin-bottom: 30px; display: block; text-align: center; font-size: 10px; color: #fff;}
.mynotif {padding: 10px; border-radius: 3px; margin: 20px 0 20px; display: block; text-align: center; font-size: 13px; color: #fff;}
.mynotif a {color: #fff; text-decoration: underline;}
.myinfo {background: #0099cc;}
.myerror {background: #a71d2a;}
.mysuccess {background: #093;}
.mywarn {background: #ff9330;}
.myinstructions {font-size: 12px; padding: 8px 8px 4px !important; font-weight: 300; background: #ff9330;}
.myinstructions .link {font-weight:bold; color:#302e52;}
.myinfobox {border: 1px solid #ccc; padding: 10px; border-radius: 3px; margin-bottom: 30px; background-color:#f2f2f2; display: block; text-align: left !important; font-size: 10px; color: #444;}
.myinfobox h4 {font-size: 18px; font-weight:bold; text-transform: uppercase;}
.myinfobox h5 {font-size: 16px; font-weight:bold; text-transform: capitalize;}
.myinfobox p {font-size: 14px;}
.myinfobox .btnx {padding: 6px 12px; background: #093; color: #fff; text-align:center; box-shadow: none; font-size: 14px;}
.myinfobox .link {font-weight:bold; color:#302e52;}

/* mytabs */
.mytabs {margin-bottom: 10px; margin-top: -15px;}
.mytabs .btn-group, .mytabs form {margin:0 !important;}
.mytabs .btn-group .btn {padding: 2px 8px !important; font-size: 10px; font-weight: 300; background: transparent; color: #8898aa; border: 1px solid #8898aa;}
.mytabs .btn-group .btn:focus {outline: none; box-shadow: none;}
.mytabs .btn-group .btn-approve {background: #093; color: #fff; border: 1px solid #093;}
.mytabs .btn-group .btn-disapprove {background: #dc3545; color: #fff;border: 1px solid #dc3545;}
.mytabs .btn-group .btn-view {background: #0250bb; color: #fff;border: 1px solid #0250bb;}
.mytabs .btn-group .btn-edit {background: transparent; color: #0250bb; border: 1px solid #0250bb;}
.mytabs .btn-group .active {background-color: #007bff; color: #fff; border: 1px solid #007bff;}
@media(max-width:768px){.nodisplay {display:none !important;}}

/* roles // color themes */
.bg-applicant {background: #800020 !important;}
.bg-student {background: #009933 !important;}
.bg-staff {background: #0f1e7a !important;}
.bg-head {background: #25003e !important;}
.bg-admin {background: #001c41  !important;}
.bg-super {background: #0f1e7a !important;}

/********************************************/
/****************** D A S H *****************/
/********************************************/
/* dash */
.dash {position: relative;  min-height: 100vh; background: #f4f6f9;}
.dash-app {display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; -webkit-box-flex: 2; -webkit-flex-grow: 2; -ms-flex-positive: 2; flex-grow: 2; margin-top: 60px;}
@media (min-width: 992px) {
.dash-app {margin-left: 238px;}
.dash-compact .dash-app {margin-left: 0;}}

/* dash-nav */
.dash-nav {min-width: 238px; box-shadow:0 4px 4px rgba(0,0,0,0.3); border-right:1px solid #fff; position: fixed; left: 0; top: 0; bottom: 0; overflow: auto;}
.dash-compact .dash-nav {display: none;}
.dash-nav::-webkit-scrollbar {width: 0; background: transparent;}
@media (max-width: 991.98px) {
.dash-nav {display: none; position: fixed; top: 0; right: 0; left: 0; bottom: 0; z-index: 1070;}
.dash-nav.mobile-show {display: block;}}
.dash-nav header {min-height: 84px; padding: 8px 23px; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center;}
.dash-nav header .menu-toggle {display: none; margin-right: auto;}
@media (max-width: 991.98px) {
.dash-nav header .menu-toggle {display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex;}}
.dash-nav header .dash-rank {margin-right: auto;}
.dash-nav a {color: #fff;}
.dash-nav a:hover {text-decoration: none;}
.dash-nav.dash-nav-dark {background-color: #fff;}
.dash-nav.dash-nav-dark a {color: #fff;}

/* dash-role themes */
.dash-nav.dash-nav-applicant {background-color: #800020;color:#fff !important;}
.dash-nav.dash-nav-student {background-color: #009933;color:#fff !important;}
.dash-nav.dash-nav-staff {background-color: #0f1e7a;color:#fff !important;}
.dash-nav.dash-nav-head {background-color: #25003e;color:#fff !important;}
.dash-nav.dash-nav-admin {background-color: #001c41;color:#fff !important;}
.dash-nav.dash-nav-super {background-color: #0f1e7a;color:#fff !important;}

/********************************************/
/**************** H E A D E R ***************/
/********************************************/
/* dash-toolbar */
.dash-toolbar {min-height: 60px; box-shadow:0 4px 4px rgba(0,0,0,0.3); display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display:flex; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items:center; padding: 3px 10px; position: fixed; top: 0; right: 0; left: 0; z-index: 1000;}
.dash-toolbar h5 {font-size: 18px; margin-top: 7px;}
.menu-toggle {position: relative; width: 42px; height: 42px; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; color: #fff;}
.menu-toggle i {font-size: 20px;}
@media (min-width: 992px) {.dash-toolbar {left: 238px;} .dash-compact .dash-toolbar {left: 0;}}

/* header actions */
.menu-toggle:hover, .menu-toggle:active, .menu-toggle:focus {text-decoration: none; color: #ccc; outline: none; cursor: pointer;}

/********************************************/
/******************* SIDEBAR ****************/
/********************************************/
/* dash-rank */
.dash-rank {font-size: 16px; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center;}
.dash-rank a {display: block; text-align: center; margin: 0 auto;}
.dash-rank:focus, .dash-rank:active, .dash-rank:hover {color: #515151; outline: none; text-decoration: none;}
.dash-rank img {display: block; margin: 0 auto;}

/* dash-info */
.dash-info {padding: 10px 20px; text-align: center; border-bottom: 1px solid #ccc; border-top: 1px solid #ccc;}
.dash-info h3 {color: #fff; font-size: 14px; line-height: 20px; margin: 0 auto; padding: 0;}
.dash-info h4 {color: #523ced; font-size: 12px; line-height: 20px; margin: 0 auto; padding: 0;}
.dash-info h5 {color: #ccc; font-size: 10px; line-height: 20px; margin: 0 auto; padding: 0;}
.dash-info .imgbox {width:50px; height:50px; text-align:center; margin:0 auto 10px;}
.dash-info .imgbox img {border-radius:50%; display:block; margin:0px auto 20px;}

/* dash-nav-list */
.dash-nav-list {display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column;}
.dash-nav-list .line-break {margin: 10px 0; height: 1px; background: #ccc; width: 100%;}
.dash-nav-item {color:#fff; padding: 5px 20px 10px 60px; font-size: 14px; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; transition:0.4s all ease-in-out;}
.dash-nav-item i {width: 36px; margin-top: 2px; font-size: 13px; color:#fff; margin-left: -40px;}
.dash-nav-dark .dash-nav-item:hover,
.dash-nav-dark .dash-nav-item:focus {outline: none; color: #e90015; background-color: rgba(0, 0, 0, 0.2);}
.dash-nav-list button {min-height: 28px; display:block; font-size: 12px; padding: 6px 20px 3px 70px; transition:0.4s all ease-in-out; border: none; background: transparent; color: #fff;}
.dash-nav-list button i {width: 36px; margin-right: 5px; margin-top: 0; font-size: 14px; color:#fff; margin-left: -50px;}

/* dropdown */
.dash-nav-dropdown {display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column;}
.dash-nav-dark .dash-nav-dropdown.show {background: rgba(0, 0, 0, 0.2); outline: none;}
.dash-nav-dropdown.show > .dash-nav-dropdown-toggle:after {-webkit-transform: none; -o-transform: none; transform: none;}
.dash-nav-dropdown.show > .dash-nav-dropdown-menu {display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex;}
.dash-nav-dropdown.show .dash-nav-dropdown.show {background: none;}
.dash-nav-dark .dash-nav-dropdown-toggle:focus, .dash-nav-dropdown-item:focus .dash-nav-dropdown-toggle {outline: none;}
.dash-nav-dropdown-toggle:after {content: ""; margin-left: auto; display: inline-block; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #fff; -webkit-transform: rotate(90deg); -o-transform: rotate(90deg); transform: rotate(90deg);}
.dash-nav-dark .dash-nav-dropdown-toggle:after {border-top-color: #fff;}
.dash-nav-dropdown-menu {display: none; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column;}
.dash-nav-dropdown-item {color: #fff !important;}
.dash-nav-dropdown-item {transition:0.4s all ease-in-out; line-height: 19px; font-size: 11px; padding: 5px 20px 5px 65px; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center;}
.dash-nav-dropdown-item:hover {background-color: rgba(0, 0, 0, 0.2);}

/********************************************/
/************** DASH CONTENT ****************/
/********************************************/
/* main_crumb */
.main_crumb {background: #573ff9; margin: 0; width: 100%; padding:20px 25px; height: auto; position: fixed; z-index: 999;}
.main_crumb a {font-size: 14px; margin: 0; padding:0; color: #fff; font-weight: 600; }
.main_crumb a:hover {text-decoration: underline;}
.main_crumb span {width: 20px; color: #fff; display: inline-block; text-align:center;}
.main_crumb small {font-weight: 300; font-size: 9px; float: right; margin: 8px 220px 0 0; display: block; color: #fff;}
@media (max-width: 992px) {.main_crumb {padding: 12px 15px;} .main_crumb small {margin: 8px 0;}}

/* dash-content */
.dash-content {background:#f4f6f9; margin-top: 50px; padding: 20px 10px; margin-bottom: 0;}
@media (max-width: 992px) {.dash-content {padding: 20px 0px;}}

/* dash-stats */
.dash-stats {margin-bottom:15px; padding: 18px; box-shadow: 0 8px 6px -6px #222; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction:column; -ms-flex-direction:column; flex-direction:column; min-height:20px; border: 1px solid #41aedc;background:linear-gradient(#41aedc,#06f0f3);-webkit-background:linear-gradient(#41aedc,#06f0f3);-moz-background:linear-gradient(#41aedc,#06f0f3); border-radius: 3px;}
.dash-stats .stats-title {font-size: 17px; color:#fff; font-weight: bold; text-align: center; text-transform: uppercase; margin-bottom: 5px;}
.dash-stats .stats-desc {font-size: 12px; color:#fff; font-weight: 300; text-align: center; margin-bottom: 0px;}

/* items_box */
.items_box {padding:20px 18px; box-shadow:0 8px 6px -6px #222; margin-bottom:30px; border:1px solid #ccc; background: #fff; border-radius: 6px; margin-top: 25px;}
.items_box h4 {font-size: 17px; padding-bottom: 0; color: #302e52; font-weight: 600; margin: 0;}
.items_box h4 .badge {font-size: 10px; padding: 2px 5px; font-weight: 300; margin-left: 5px;}
.items_box h4 span {font-size: 17px; display: inline-block; color: #a71d2a; font-variant: small-caps;}
.items_box small {font-size: 9px; display: block; border-bottom: 1px solid #ccc; padding: 0; padding-bottom: 10px; margin: 0 0 20px}
.items_box .xadd {padding: 3px 6px; width: auto; margin: 0px; color:#fff; font-size: 10px; background: #0099cc; border-color: #0099cc;}
.items_box .xadd:hover, .items_box .xadd:focus {outline: none; box-shadow:none; background-color: #0099cc; border-color: #0099cc;}
.items_box .mytitle {background: #444;border-radius: 3px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px; color: #fff; font-size: 14px; padding: 2px 6px; margin-bottom: 0; text-transform: uppercase;}
.items_box .mysubtitle {border:1px solid #ccc; border-radius: 3px; border-top-right-radius: 0px; border-top-left-radius: 0px; border-top: none; font-size: 14px;background: #f0f0f0; padding: 10px 6px; color: #444;}
.items_box .form-group {margin-bottom:5px;}

/* items_box // table */
.items_box thead {background: #f6f9fc;}
.items_box thead th {font-size: 10px; line-height: 12px; text-transform: uppercase; color: #8898aa;}
.items_box tbody td {font-size: 10px; line-height: 16px; color: #525f7f;}
.items_box tbody td a.link {font-size: 10px; display:block; text-decoration: underline; line-height: 16px; color: #525f7f;}
.items_box tbody td .badge {font-size: 10px; color: #fff; padding: 2px 5px; font-weight: 300; margin-left: 0px; background: #8898aa;;}
.items_box tbody td .badge-danger {background: #dc3545; color: #fff;border: 1px solid #dc3545;}
.items_box tbody td .badge-success {background: #093; color: #fff;border: 1px solid #093;}
.items_box tbody td .btn-group, .items_box tbody td form {margin:0 !important;}
.items_box tbody td .btn-group .btn {padding: 2px 8px !important; font-size: 9px; font-weight: 300; background: transparent; color: #8898aa; border: 1px solid #8898aa;}
.items_box tbody td .btn-group .btn-approve {background: #093; color: #fff; border: 1px solid #093;}
.items_box tbody td .btn-group .btn-disapprove {background: #dc3545; color: #fff;border: 1px solid #dc3545;}
.items_box tbody td .btn-group .btn-view {background: #0250bb; color: #fff;border: 1px solid #0250bb;}
.items_box tbody td .btn-group .btn-edit {background: transparent; color: #0250bb; border-top: 1px solid #0250bb; border-bottom:1px solid #0250bb;}
.items_box tbody td .pagination {margin: 0px auto;}
.items_box tbody td .pagination li {padding:0;}
.items_box tbody td .pagination li a {padding: 4px 10px; border:1px solid #0099cc; color:#fff; background: #0099cc !important;}
.items_box tbody td .pagination li:first-child {border-top-left-radius:.3rem;border-bottom-left-radius:.3rem}
.items_box tbody td .pagination li:last-child {border-top-right-radius:.2rem;border-bottom-right-radius:.2rem}
.items_box a, .items_box tbody td .btn-group .btn {text-decoration: none; box-shadow: none; outline: none;}
.items_box a:hover, .items_list_box a:focus {text-decoration: none; outline: none;}
.items_box tbody td .btn-group .btn:focus {text-decoration: none; box-shadow: none; outline: none;}
.btn-group a:focus {background: #302e52; outline:none; box-shadow:none;}
.radio-label {display:inline-block; margin-left: 10px; margin-right: 10px; }

/* items_box // card */
.items_box .card-header {background-color: #eef8ff; padding: 0;}
.items_box .card-header .btn-head {text-decoration:none;color:#0f1e7a;border-radius:0;box-shadow:none;font-size:13px;font-weight:bold; padding:6px 15px;}
.items_box .card-header h2 button.done {color: #009933 !important;}
.items_box .card-header .btn .badge {font-size: 10px; padding: 2px 5px; font-weight: 300; margin-left: 5px;}
.items_box .card-body .btn {height: 33px; margin: 0 auto; font-size: 14px; border-radius: 4px;}
.items_box .card-body .title {background: #444; color: #fff; font-size: 14px; padding: 2px 6px; margin-bottom: 0;}
.items_box .card-body .subtitle {font-size: 12px;}
.items_box .card-body .desc {font-size: 12px; border-bottom: 1px solid #ccc; padding-bottom: 10px;}
.items_box .card-body .desc b {font-weight: bold;}
.items_box .card-body .btnedit {font-size: 9px; padding: 3px 6px 2px; height: auto; background: #0099cc; border-color: #0099cc;}
.items_box .card-body .btndel {font-size: 9px; padding: 3px 6px 2px; height: auto; background: #a71d2a; border-color: #a71d2a; margin-right: 5px;}
.items_box .card-body .form-group {margin-bottom: 10px;}

/* items_box // forms */
.items_box label {font-weight: 600; font-size: 12px; color: #302e52; margin-bottom: 0; font-weight: bold;}
.items_box label span {font-size: 10px; color: #f60; font-weight: bold;}
.items_box .form-control {box-shadow: none; font-size: 12px; padding: 3px 12px;}
.items_box .form-control::placeholder {color: #a0a0a0; font-size: 12px;}
.items_box .help-block {font-size: 11px; color: #8898aa;}
.items_box .btn-submit {padding:4px 12px !important; display: block; background: #093; border-radius: 4px; font-size: 11px; border-color: #093; box-shadow: none; color: #fff;}
.items_box .btn-submit2 {float:left; background: #09c; border-color: #09c;}

/* items_box // search // filter */
.items_box .form-group #search {color: #a0a0a0; font-size: 12px; font-weight: normal;}
.items_box .form-group #search::placeholder {color: #a0a0a0; font-size: 12px; font-weight: normal;}
.items_box .search {margin-bottom: 0;}
.items_box .search select {box-shadow: none; font-size: 12px; padding: 4px 6px !important;}
.items_box .btn-filter {padding:4px 12px !important; background: #09c; display: block; border-color: #09c; font-size: 11px;}

/* checkbox */
.items_box .table .ccbox {display: block; position: relative; padding-left: 0; margin-bottom: 0; cursor: pointer; font-size: 9px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
.items_box .table .ccbox input {position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0;}
.items_box .table .ccbox .checkmark {position: absolute; top: 0; left: 0; height: 20px; width: 20px; background-color: #eee; border:1px solid #2196F3;}
.items_box .table .ccbox:hover input ~ .checkmark {background-color: #ccc;}
.items_box .table .ccbox input:checked ~ .checkmark {background-color: #2196F3;}
.items_box .table .ccbox .checkmark:after {content: ""; position: absolute; display: none;}
.items_box .table .ccbox input:checked ~ .checkmark:after {display: block;}
.items_box .table .ccbox .checkmark:after {left: 6px; top: 3px; width: 6px; height: 10px; border: solid white; border-width: 0 3px 3px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg);}

/* profile_list */
.profile_list {border: 1px solid #8898aa; border-radius: 4px; margin: 0 0 20px; padding: 0; min-height: 30px;}
.profile_list li {list-style: none; padding: 6px 10px; border-bottom: 1px solid #8898aa; display: flex;}
.profile_list li .form-control {height: 25px; font-size: 10px; font-weight: normal;}
.profile_list li .form-control::placeholder {color: #a0a0a0; font-size: 10px; font-weight: normal;}
.profile_list li .btn-submit {max-width: 180px; margin: 0 auto; max-height: 35px; font-size: 12px;}
.profile_list li:last-child {border-bottom: none;}
.profile_list li span {width: 50%; font-weight: 400; display: block; color: #32325d; text-align: left; font-size: 12px; line-height: 20px;white-space:nowrap; overflow: hidden; text-overflow: ellipsis;}
.profile_list li span.text-white {width: 100%;white-space:nowrap; overflow: hidden; text-overflow: ellipsis;}

footer {position: fixed; bottom: 0; background:#fff; box-shadow: 0 -8px 6px -6px rgba(31, 73, 125, 0.8); width: 100%; padding: 20px 30px;}
footer p {margin-bottom:0; padding: 0; color: #525f52; font-size: 13px; line-height: 20px;}

/* .modal */
.modal {margin-top: 10px;}
.modal .modal-header {background-color: #1a73e8;color: #FFF; padding: 10px;}
.modal .modal-header h4 {text-align: left; font-size: 14px; margin-top: 5px;}
.modal .modal-header button {margin-right: 0; padding-right: 0; color: #fff;}
.modal .modal-header button:focus {outline: none; box-shadow: none;}
.modal .modal-body {padding-top: 15px;}
.modal .modal-body .btn {background: #009933; padding: 1px 10px; display: inline-block; font-size: 11px; background: #0099cc; border-color: #0099cc; color:#fff;}

/* .modal .modal-body // forms */
.modal .modal-body label {font-weight: 600; font-size: 13px; color: #302e52; margin-bottom: 0;}
.modal .modal-body label span {font-size: 12px; color: #f60; font-weight: bold;}
.modal .modal-body .form-control {box-shadow: none; font-size: 14px;}
.modal .modal-body .form-control::placeholder {color: #a0a0a0; font-size: 14px;}
.modal .modal-body .help-block {font-size: 12px; color: #8898aa;}
.modal .modal-body .btn-submit {background: #00d548; border-radius: 4px; border-color: #00d548; box-shadow: none; color: #fff;}

/* settings  */
.settings .form-group {margin-bottom: 10px; display: block;}
.settings .switch {position: relative; display: inline-block; width: 50px; height: 24px; float:right; margin-top: -20px;}
.settings .switch input {opacity: 0; width: 0; height: 0;}
.settings .slider {position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s;}
.settings .slider:before {position: absolute; content: ""; height: 16px; width: 14px; left: 4px; bottom: 4px; background-color: white; -webkit-transition: .4s; transition: .4s;}
.settings input:checked + .slider {background-color: #2196F3;}
.settings input:focus + .slider {box-shadow: 0 0 1px #2196F3;}
.settings input:checked + .slider:before {-webkit-transform: translateX(26px); -ms-transform: translateX(26px); transform: translateX(26px);}
.settings .slider.round {border-radius: 34px;}
.settings .slider.round:before {border-radius: 50%;}
.settings .btn {padding: 3px 12px; font-size: 12px;}
